<template>
  <div>
    <FunnelBreadcrumb v-if="!hasMinimal" />

    <div :class="wrapperClass">
      <div class="flex h-full w-full flex-col justify-center p-24 md:w-[34rem]">
        <RevStepper
          v-if="hasMinimal"
          :active-step="STEP_NAMES.SHIPPING"
          :alternative-text-back="i18n(translations.previousPage)"
          :alternative-text-close="i18n(translations.stepperClose)"
          :alternative-text-completed="i18n(translations.stepperCompleted)"
          :alternative-text-current="i18n(translations.stepperCurrent)"
          :has-close="false"
          :hasBack="hasBackButton"
          :steps="steps"
          @back="goBack"
        />
        <div
          v-if="!hasMinimal"
          class="border-static-default-low flex justify-center border-b-2 py-16"
        >
          <span class="body-1">
            {{ i18n(translations.headerTitle) }}
          </span>
        </div>

        <div class="flex flex-col justify-start pt-32">
          <span class="body-1-bold">
            {{ i18n(translations.question) }}
          </span>
          <span class="body-1 my-16">
            {{ i18n(translations.description) }}
          </span>
          <FormGenerator
            :form-config="formConfig"
            form-id="shipping-form"
            :isLoading="isLoading"
            submit-button-id="shipping-submit"
            :submit-button-label="i18n(translations.button)"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { navigateTo, useRoute, useRouter } from '#imports'
import { ref } from 'vue'

import { BUYBACK_SHIPPING_MODE } from '@backmarket/http-api/src/api-specs-buyback/constants'
import { postOrder } from '@backmarket/http-api/src/api-specs-buyback/customer/customer'
import { getBuybackShippingV1 } from '@backmarket/http-api/src/api-specs-buyback/customer/getBuybackShippingV1'
import { HttpApiError } from '@backmarket/http-api/src/utils/HttpApiError'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { tw } from '@backmarket/utils/string/tw'
import { RevStepper } from '@ds/components/Stepper'
import { storeToRefs } from 'pinia'

import { useResaleStepper } from '~/scopes/buyback/composables/useResaleStepper'
import { useGenerateShippingForm } from '~/scopes/buyback/config/useGenerateShippingForm'
import {
  ERROR_TYPES,
  ROUTE_NAMES,
  STEP_NAMES,
} from '~/scopes/buyback/constants'
import { FUNNEL_ERROR_MESSAGE } from '~/scopes/buyback/pages/constants'
import { useBuyBackStore } from '~/scopes/buyback/store'
import { CMS } from '~/scopes/cms/routes-names'

import FormGenerator, {
  type FormValues,
} from '../components/FormGenerator/FormGenerator.vue'
import FunnelBreadcrumb from '../components/FunnelBreadcrumb/FunnelBreadcrumb.vue'

import translations from './Shipping.translations'

const i18n = useI18n()
const isLoading = ref(false)
const tracking = useTracking()
const route = useRoute()
const router = useRouter()
const logger = useLogger()
const { openErrorToast } = useTheToast()
const experiments = useExperiments()

const buybackStore = useBuyBackStore()
const { offer, hasOffer } = storeToRefs(buybackStore)

if (!hasOffer.value) {
  router.push({
    name: CMS.BUYBACK,
    params: {
      pageName: 'home',
    },
    ...((route?.query?.partner && {
      query: { partner: route.query.partner },
    }) ||
      {}),
  })
}

const { steps, goBack, hasBackButton } = useResaleStepper({
  activeStepName: STEP_NAMES.ADDRESS,
  hasShippingStep: offer.value?.has_shipping_choices,
})
const { data: dataV1 } = await useHttpFetch(getBuybackShippingV1, {
  pathParams: {
    kind: 'regular',
    listingPublicId: route.query?.listingPublicId,
  },
})

const formConfig = useGenerateShippingForm(dataV1.value)
const hasMinimal = experiments['experiment.buybackHeader'] === 'withMinimal'
const wrapperClass = hasMinimal
  ? tw`flex justify-center`
  : tw`bg-surface-default-low flex justify-center md:py-32`

async function handleSubmit(values: FormValues) {
  isLoading.value = true
  try {
    tracking.trackClick({
      zone: 'buyback',
      name: 'shipping_info',
      value: {
        category: route.params?.id,
        shipping: values['shipping-mode'],
      },
    })

    if (
      !Object.values(BUYBACK_SHIPPING_MODE).includes(
        String(values['shipping-mode']),
      )
    ) {
      logger.error('[TEMP] Invalid shipping mode', {
        shippingMode: values['shipping-mode'],
        offer: offer.value,
        shippingData: dataV1.value,
        zone: 'funnel',
      })
    }

    const payload = await $httpFetch(postOrder, {
      body: {
        customerAddressId: route.params?.addressId,
        listing_id: route.params?.listingId,
        shippingId: values['shipping-mode'],
      },
    })

    tracking.trackBuybackConfirmation({
      buybackType: 'bb_normal',
      buybackOrderId: payload.id,
      commission: payload.estimatedCommission?.amount,
      conversionValue: payload.price,
      conversionCurrency: payload.estimatedCommission?.currency,
    })

    navigateTo({
      name: ROUTE_NAMES.CONFIRMATION,
      params: {
        id: payload.id,
      },
    })
  } catch (shippingError) {
    const error = shippingError as HttpApiError | Error
    logger.error(FUNNEL_ERROR_MESSAGE.POST_ORDER, { error })

    if (shippingError instanceof HttpApiError) {
      if (shippingError.type === ERROR_TYPES.INVALID_ID_ERROR_TYPE) {
        openErrorToast({ content: i18n(translations.errorUnableToProcess) })
      } else if (shippingError.type === ERROR_TYPES.NOT_FOUND_ERROR_TYPE) {
        openErrorToast({ content: i18n(translations.errorListingNotFound) })
      } else {
        openErrorToast({
          title: i18n(translations.errorTitle),
          content: i18n(translations.errorDescription),
        })
      }
    } else {
      openErrorToast({
        title: i18n(translations.errorTitle),
        content: i18n(translations.errorDescription),
      })
    }
    isLoading.value = false
  }
}
</script>
